<template>
	<div class="bordered">
		<Title>设备投入</Title>
		<TotalAnalysis style="marginBottom: 0" :value="numberFun" genre="个"></TotalAnalysis>
		<div class="personListWrapper">
			<div class="personRow" :key="d.name" v-for="(d, k) in mockDataByLevel">
				<div class="name">{{ d.name }}</div>
				<div class="num"><countTo :startVal="0" :endVal="d.value" :duration="CountAnimationDuration"></countTo>{{k == 1 ? '%' : ''}}</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import countTo from 'vue-count-to';
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import { mutBarChart } from '../common';
import { randomByLevel } from '@/views/homePage/common';
import { CountAnimationDuration } from '@/views/homePage/common';
import TotalAnalysis from '../components/TotalAnalysis.vue'
export default {
	components: { Title, BaseEchart, countTo, TotalAnalysis },
	data() {
		return {
			CountAnimationDuration,
			option: undefined,
			number: 59860,
			mockData: [
				{ name: '设备种类', value: 1200 },
				{ name: '设备利用率', value: 80 },
				{ name: '设备使用时长', value: 72 },
				{ name: '覆盖人群', value: 1600 },
			],
		};
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		numberFun(){
      return this.number / (this.mapLevel + 1)
    },
    mockDataByLevel() {
			return this.mockData.map((d) => {
				return { ...d, value: d.value / (this.mapLevel + 1) };
			});
		},
	},
	watch: {
    mapDataArr: {
      handler() {
        const dataX = this.mapDataArr.map((d) => {
          return { name: d.regionName, value: randomByLevel({level: this.mapLevel}) };
        })
        this.option = mutBarChart({ 
					leArr: ['教师总数', '名师讲堂', '中医类'],
					xData: dataX.map((d) => d.name), 
					yFirst: dataX.map((d) => d.value * 8), 
					ySecond: dataX.map((d) => d.value * 10),
					yThird: dataX.map((d) => d.value * 7) 
				});
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.value {
	color: $main-text;
	font-size: 22px;
}
.personListWrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	margin: 16px 16px 0;
}
.personRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 66px;
	margin-bottom: 16px;
	background-color: #0c2857;

	.name {
		width: 150px;
		height: 34px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding-left: 20px;
		font-size: 18px;
		background-image: url('../assets/overview-person-bg.jpg');
		background-size: 100% 100%;
	}
	.num{
		font-size: 28px;
		color: #0beffd;
		margin-right: 26px;
	}
}
</style>
