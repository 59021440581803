<template lang="">
  <el-row class="page" style="margin: 0" :gutter="16">
    <el-col :span="6">
			<LeftTop></LeftTop>
			<LeftCenter></LeftCenter>
		</el-col>
    <el-col :span="12">
			<EchartsMap :height="750"></EchartsMap>
			<CenterBottom></CenterBottom>
		</el-col>
    <el-col :span="6">
			<RightTop></RightTop>
			<RightBottom></RightBottom>
		</el-col>
	</el-row>
  </div>
</template>
<script>
import '@/assets/icon-font/ABDUCTION2000.css';
import LeftTop from './LeftTop.vue';
import LeftCenter from './LeftCenter.vue';
import EchartsMap from './EchartsMap.vue';
import CenterBottom from './CenterBottom.vue';
import RightTop from './RightTop.vue';
import RightBottom from './RightBottom.vue';
import MapData from '@/views/homePage/overview/mapData.vue';
export default {
	components: {LeftTop, LeftCenter, CenterBottom, RightTop, RightBottom, EchartsMap },
	data() {
		return {
			option : null
		}
	},
};

</script>
<style lang="scss" scoped>
.m_xmaps{
	height: 750px;
}
</style>